 <template>
<div>

    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i> <span class="h5">  Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA LAS DELEGACIONES DE TOMA DECISIONES:
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class="my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-child fa-3x pb-1"></i>
                            <br>
                            <span class="lg-numero">{{listaDelegaciones.length}}</span>
                            <br>
                            <span class="text-muted">Delegaciones de toma de decisiones registradas</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestión de delegación de toma de decisiones</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col v-if="checkPermissions('017-37001-DTD','c') == 1" cols="6" class="my-2">
                            <b-button block variant="custom" @click="modalNuevaDelegacion = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Nueva delegación</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5">Delegaciones de toma de decisiones registradas</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="listaDelegaciones" :fields="cabecera" bordered :per-page="porPagina" :current-page="currentPage" @filtered="onFiltered" :filter="filter" :filter-included-fields="filterOn" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #cell(internoExterno)="param">
                                    <span v-if="param.item.internoExterno==1">Interno</span>
                                    <span v-else-if="param.item.internoExterno==2">Externo</span>
                                </template>
                                <template #cell(opciones)="param">
                                    <b-button v-if="checkPermissions('017-37001-DTD','u') == 1" @click="abrirModalActualizarDelegacion(param)" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-button>
                                    <b-button v-if="checkPermissions('017-37001-DTD','d') == 1" @click="eliminarDelegacion(param)" size="sm" class="mr-1 mb-1" variant="danger" v-c-tooltip="'Eliminar'">
                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                    </b-button>
                                </template>
                                <template v-slot:cell(idPuestoTrabajo)="param">
                                    {{comboPuestos.find(x => x.idPuestoTrabajo==param.item.idPuestoTrabajo).puestoTrabajo}}
                                </template>
                                <template v-slot:cell(idPuestoTrabajoRiesgo)="param">
                                    {{comboPuestos.find(x => x.idPuestoTrabajo==param.item.idPuestoTrabajoRiesgo).puestoTrabajo}}
                                </template>
                                <template v-slot:cell(idRiesgo)="param">
                                    {{'* ' +arrayRiesgos.filter((x) => param.value.includes(x.idRiesgo)).map((y) => ` ${y.nombre} `).join(' * ')}}
                                </template>
                                <template v-slot:cell(transferido)="param">
                                    <span v-if="param.item.transferido==2">SI</span>
                                    <span v-if="param.item.transferido==1">NO</span>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>

    <CModal :closeOnBackdrop="false" size="lg" color="primario" :show.sync="modalNuevaDelegacion">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nueva delegación</span>
            </h6>
            <CButtonClose @click="modalNuevaDelegacion = false" class="text-white" />
        </template>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(nuevaDelegacion)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="puesto_" rules="required" v-slot="{errors}">
                            <b-form-group label="Puesto de trabajo con riesgo medio o alto:" class="mb-2">
                                <v-select @input="obtenerRiesgosPuesto" :reduce="comboPuestosRiesgos =>comboPuestosRiesgos.idPuestoTrabajo" label="puestoTrabajo" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosDelegacion.idPuestoTrabajoRiesgo , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosDelegacion.idPuestoTrabajoRiesgo" :options="comboPuestosRiesgos.filter(x => x.tipo>6)" @search:blur="blurPuestoTrabajoRiesgo">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar.</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="riesgo" rules="required" v-slot="{errors}">
                            <b-form-group label="Riesgos del puesto de trabajo:" class="mb-2">
                                <v-select multiple :reduce="listaRiesgos =>listaRiesgos.idRiesgo" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosDelegacion.idRiesgo.length>0 , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosDelegacion.idRiesgo" :options="listaRiesgos.filter(x => x.tipo>6)" @search:blur="blurRiesgo">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar.</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="puesto" rules="required" v-slot="{errors}">
                            <b-form-group label="Puesto de trabajo que asume responsabilidad:" class="mb-2">
                                <v-select :reduce="comboPuestos =>comboPuestos.idPuestoTrabajo" label="puestoTrabajo" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosDelegacion.idPuestoTrabajo , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosDelegacion.idPuestoTrabajo" :options="comboPuestos" @search:blur="blurPuestoTrabajo">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar.</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="Transferido" >
                            <b-form-group label="Transferido:" class="mb-2">
                                <b-form-radio-group plain v-model="datosDelegacion.transferido" :options="[{value:2,text:'SI'},{value:1,text:'NO'}]"></b-form-radio-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="observación" v-slot="validationContext">
                            <b-form-group label="Observación:" class="mb-2">
                                <b-form-textarea rows="3" max-rows="6" v-model="datosDelegacion.observacion" :state="getValidationState(validationContext)" placeholder="Ingrese el nombre de la publicación"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalNuevaDelegacion = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :closeOnBackdrop="false" size="lg" color="primario" :show.sync="modalActualizarDelegacion">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Actualizar inspección</span>
            </h6>
            <CButtonClose @click="modalActualizarDelegacion = false" class="text-white" />
        </template>

        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarDelegacion)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="puesto_" rules="required" v-slot="{errors}">
                            <b-form-group label="Puesto de trabajo con riesgo medio o alto:" class="mb-2">
                                <v-select @input="obtenerRiesgosPuesto" :reduce="comboPuestosRiesgos =>comboPuestosRiesgos.idPuestoTrabajo" label="puestoTrabajo" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosDelegacionActualizar.idPuestoTrabajoRiesgo , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosDelegacionActualizar.idPuestoTrabajoRiesgo" :options="comboPuestosRiesgos.filter(x => x.tipo>6)" @search:blur="blurPuestoTrabajoRiesgo">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar.</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="riesgo" rules="required" v-slot="{errors}">
                            <b-form-group label="Riesgos del puesto de trabajo:" class="mb-2">
                                <v-select multiple :reduce="listaRiesgos =>listaRiesgos.idRiesgo" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosDelegacionActualizar.idRiesgo.length>0 , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosDelegacionActualizar.idRiesgo" :options="listaRiesgos.filter(x => x.tipo>6)" @search:blur="blurRiesgo">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar.</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="puesto" rules="required" v-slot="{errors}">
                            <b-form-group label="Puesto de trabajo que asume responsabilidad:" class="mb-2">
                                <v-select :reduce="comboPuestos =>comboPuestos.idPuestoTrabajo" label="puestoTrabajo" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosDelegacionActualizar.idPuestoTrabajo , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosDelegacionActualizar.idPuestoTrabajo" :options="comboPuestos" @search:blur="blurPuestoTrabajo">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar.</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="3">
                        <validation-provider name="Transferido" >
                            <b-form-group label="Transferido:" class="mb-2">
                                <b-form-radio-group plain v-model="datosDelegacionActualizar.transferido" :options="[{value:2,text:'SI'},{value:1,text:'NO'}]"></b-form-radio-group>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="observación" v-slot="validationContext">
                            <b-form-group label="Observación:" class="mb-2">
                                <b-form-textarea rows="3" max-rows="6" v-model="datosDelegacionActualizar.observacion" :state="getValidationState(validationContext)" placeholder="Ingrese el nombre de la publicación"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalActualizarDelegacion = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {

    },
    data() {
        return {
            modalNuevaDelegacion: false,
            modalActualizarDelegacion: false,

            totalRows: 0,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            cabecera: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "idPuestoTrabajoRiesgo",
                    label: "Puesto de trabajo original",
                    class: "text-center",
                },
                {
                    key: "idRiesgo",
                    label: "Riesgos",
                    class: "text-center",
                },
                {
                    key: "idPuestoTrabajo",
                    label: "Puesto que asume la responsabilidad",
                    class: "text-center",
                },
                {
                    key: "transferido",
                    label: "Transferido",
                    class: "text-center",
                },
                {
                    key: "observacion",
                    label: "Observación",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                }
            ],
            campoPersonas: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "control",
                    label: "Control",
                    class: "text-center",
                },
                {
                    key: "acciones",
                    label: '',
                    class: "text-center"
                }
            ],
            listaDelegaciones: [],
            datosDelegacion: {
                idCliente: '',
                idPuestoTrabajoRiesgo: '',
                idRiesgo: [],
                idPuestoTrabajoRiesgo: '',
                transferido: 2,
                observacion: '',
                ejercicio: JSON.parse(localStorage.settings).yExcs,
            },
            datosDelegacionActualizar: {
                idDelegacion: '',
                idCliente: '',
                idPuestoTrabajoRiesgo: '',
                idRiesgo: [],
                idPuestoTrabajoRiesgo: '',
                transferido: 2,
                observacion: '',
                ejercicio: '',
            },
            disabled: false,
            comboPuestos: [],
            comboPuestosRiesgos: [],
            listaRiesgos: [],
            arrayRiesgos: [],
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        blurPuestoTrabajo() {
            this.computedForm.refs.puesto.validate();
        },
        blurModificarPuestoTrabajo() {
            this.computedForm2.refs.puesto.validate();
        },
        blurPuestoTrabajoRiesgo() {
            this.computedForm.refs.puesto_.validate();
        },
        blurModificarPuestoTrabajoRiesgo() {
            this.computedForm2.refs.puesto_.validate();
        },
        blurRiesgo() {
            this.computedForm.refs.riesgo.validate();
        },
        blurModificarRiesgo() {
            this.computedForm2.refs.riesgo.validate();
        },
        obtenerRiesgosPuesto(idPuestoTrabajo){
            this.datosDelegacion.idRiesgo = [];
            this.datosDelegacionActualizar.idRiesgo = [];
            this.listaRiesgos = this.arrayRiesgos.filter(x => x.idPuestoTrabajo==idPuestoTrabajo);
        },
        listarPuestosTrabajo() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-puestos-trabajo", {
                        params: {
                            idCliente: me.datosDelegacion.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboPuestos = response.data;
                    me.listarDelegaciones();
                })
                .catch(function (error) {
                    console.log(error)
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarPuestosSensibles() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-evaluaciones-riesgos-antisoborno-sga", {
                        params: {
                            idCliente: me.datosDelegacion.idCliente,
                            ejercicio: JSON.parse(localStorage.settings).yExcs,
                        },
                    }
                )
                .then(function (response) {

                    const setObjRiesgos = new Set(); // creamos pares de clave y array
                    const unicosRiesgos = response.data.reduce((acc, item) => {
                        if (!setObjRiesgos.has(item.idRiesgo)) {
                            setObjRiesgos.add(item.idRiesgo, item)
                            acc.push(item)
                        }
                        return acc;
                    }, []);

                    me.arrayRiesgos = unicosRiesgos;

                    const setObj = new Set(); // creamos pares de clave y array
                    const unicos = response.data.reduce((acc, item) => {
                        if (!setObj.has(item.idPuestoTrabajo)) {
                            setObj.add(item.idPuestoTrabajo, item)
                            acc.push(item)
                        }
                        return acc;
                    }, []);
                    //me.listaEvaluaciones = unicos;
                    for (const i in unicos) {
                        if(unicos[i].datosAcordionRiesgo){
                            me.comboPuestosRiesgos.push({
                                idPuestoTrabajo: unicos[i].idPuestoTrabajo,
                                puestoTrabajo: unicos[i].puestoTrabajo,
                                tipo: JSON.parse(unicos[i].datosAcordionRiesgo).tipoActual,
                            })
                        }else{
                            me.comboPuestosRiesgos.push({
                                idPuestoTrabajo: unicos[i].idPuestoTrabajo,
                                puestoTrabajo: unicos[i].puestoTrabajo,
                                tipo: unicos[i].tipo,
                            })
                        }
                    }
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarDelegaciones() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-delegaciones-sga", {
                        params: {
                            idCliente: me.datosDelegacion.idCliente,
                            ejercicio: JSON.parse(localStorage.settings).yExcs,
                        },
                    }
                )
                .then(function (response) {
                    me.listaDelegaciones = response.data
                    me.totalRows = me.listaDelegaciones.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        nuevaDelegacion() {
            let me = this;
            me.disabled = true;
            
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-delegacion-sga",
                    me.datosDelegacion, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.listarDelegaciones();
                    me.modalNuevaDelegacion = false;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });
        },
        actualizarDelegacion() {
            let me = this;
            me.disabled = true;

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-delegacion-sga",
                    me.datosDelegacionActualizar, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.listarDelegaciones();
                    me.modalActualizarDelegacion = false;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });
        },
        abrirModalActualizarDelegacion(param) {
            let me = this;

            me.datosDelegacionActualizar.idDelegacion = param.item.idDelegacion;
            me.datosDelegacionActualizar.idCliente = param.item.idCliente;
            me.datosDelegacionActualizar.idPuestoTrabajoRiesgo = param.item.idPuestoTrabajoRiesgo;
            me.obtenerRiesgosPuesto(me.datosDelegacionActualizar.idPuestoTrabajoRiesgo)
            me.datosDelegacionActualizar.idRiesgo = JSON.parse(param.item.idRiesgo);
            me.datosDelegacionActualizar.idPuestoTrabajo = param.item.idPuestoTrabajo;
            me.datosDelegacionActualizar.transferido = param.item.transferido;
            me.datosDelegacionActualizar.observacion = param.item.observacion;
            me.datosDelegacionActualizar.ejercicio = param.item.ejercicio;

            me.modalActualizarDelegacion = true;
        },
        resetModalNuevaDelegacion() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosDelegacion.idPuestoTrabajoRiesgo = '';
            this.datosDelegacion.idRiesgo = [];
            this.datosDelegacion.idPuestoTrabajo = '';
            this.datosDelegacion.transferido = 2;
            this.datosDelegacion.observacion = '';
        },
        resetModalActualizarDelegacion() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });

        },
        eliminarDelegacion(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar la delegación?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-delegacion-sga", {
                            idDelegacion: param.item.idDelegacion,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarDelegaciones();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedForm2() {
            return this.$refs.observer2;
        },
    },
    watch: {
        modalNuevaDelegacion: function (val) {
            if (val == false) {
                this.resetModalNuevaDelegacion();
            }
        },
        modalActualizarDelegacion: function (val) {
            if (val == false) {
                this.resetModalActualizarDelegacion();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosDelegacion.idCliente = user.uidClient;
            //this.listarDelegaciones();
            this.listarPuestosTrabajo();
            this.listarPuestosSensibles();
        }

    }

}
</script>
